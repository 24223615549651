import React from 'react';
import { graphql, PageProps } from 'gatsby';
import AllCreditCardReviews from '../../components/CreditCards/components/AllCreditCardReviews';
import { defaultApiComplianceObject, processApiComplianceValues } from '@throttleup/esi-components';
import { processImageApiComplianceValue } from '../../utils';
import { getDefaultCardArt, getDefaultSEO } from '../../utils';
import Seo from '../../components/Seo';
import HTPageWrapper from '../../wrappers/HTPageWrapper';
import { HT_PAGE_CATEGORY_HUB_PAGE } from '../../constants';

type IAllCardReviewsTemplateDataProps = {
  cardReviews: Queries.WpPostConnection;
};

type IAllCardReviewsTemplateContextProps = {
  page: number;
  limit: number;
  skip: number;
};

const AllCardReviewsTemplate = ({
  data: { cardReviews },
  pageContext: { page, limit },
  location,
}: PageProps<IAllCardReviewsTemplateDataProps, IAllCardReviewsTemplateContextProps>) => {
  // Put at the beginning of the archive the following cards.
  const featuredCardIds = [
    205, 21336, 5, 1614, 189581, 1262, 2991, 101912, 22988, 163, 1983, 215, 2085, 1674, 285059,
    1378, 551447, 792, 99880, 68986, 11595, 69685, 9992, 1966, 2086, 75900, 54785, 1615, 1742,
    49217, 28339, 4593, 117, 166, 160869, 1377, 3652, 1925, 116950, 54778,
  ];
  const title = 'Credit Card Reviews';
  // Obtain two different arrays and later merge them.
  let featuredCards = [] as Queries.WpPost[];
  let remainingCards = [] as Queries.WpPost[];
  cardReviews.nodes.forEach((cardReview) => {
    if (featuredCardIds.includes(cardReview.databaseId)) {
      featuredCards.push(cardReview);
    } else {
      remainingCards.push(cardReview);
    }
  });

  // Sort according to the order.
  const orderedFeaturedCards = featuredCards.sort((a, b) => {
    return featuredCardIds.indexOf(a.databaseId) - featuredCardIds.indexOf(b.databaseId);
  });

  // Create the objects to display according with the pagination.
  const cardsPerPage = [...orderedFeaturedCards, ...remainingCards]
    .slice((page - 1) * limit, page * limit)
    .map((cardReview) => {
      if (cardReview?.cardDataJsonPreview) {
        const card = JSON.parse(cardReview.cardDataJsonPreview);
        const upgpCardName = processApiComplianceValues(
          card.upgpCardName || defaultApiComplianceObject,
        );
        return {
          title: {
            text: upgpCardName,
            link: card.cardReviewUrl,
          },
          excerpt: card.shortReview,
          thumbnail: card.upgpImage?.value
            ? {
                src: processImageApiComplianceValue(card.upgpImage),
                alt: upgpCardName,
              }
            : getDefaultCardArt(upgpCardName),
        };
      }

      return null;
    });

  return (
    <HTPageWrapper category={HT_PAGE_CATEGORY_HUB_PAGE} title={title} location={location}>
      <AllCreditCardReviews
        pageHeader={{
          title,
          subTitle:
            'Explore detailed reviews from our experts, giving you all the pros and cons across a huge range of credit cards.',
          breadCrumbs: [
            {
              link: '/credit-cards/',
              text: 'Credit Cards',
            },
            {
              link: '/credit-cards/reviews/',
              text: 'Credit Card Reviews',
            },
          ],
        }}
        creditCardReviews={{
          cards: cardsPerPage,
          notScrollable: true,
        }}
        pagination={{
          currentPageIndex: page - 1,
          pageSize: limit,
          total: cardReviews.totalCount,
          basePath: `/credit-cards/reviews/all`,
        }}
      />
    </HTPageWrapper>
  );
};

export default AllCardReviewsTemplate;

export const query = graphql`
  query CardReviews {
    cardReviews: allWpPost(
      filter: {
        status: { eq: "publish" }
        template: { templateName: { eq: "Credit Card Review" } }
      }
    ) {
      totalCount
      nodes {
        databaseId
        cardDataJsonPreview
      }
    }
  }
`;

export const Head = ({ location, pageContext }: PageProps) => {
  const pageTitle = 'Credit Card Reviews from UpgradedPoints.com - Archive';
  const pageNumber = pageContext.page;
  const socialTitle = pageTitle;
  const ogDescription =
    "Explore comprehensive credit card reviews from our finance and travel points experts. We've done the analysis to make sure you select the right card for you.";
  const includeCollectionPageType = true;
  const extraBreadcrumbList = [
    { name: 'Credit Cards', slug: 'credit-cards' },
    { name: 'Credit Card Reviews', slug: 'reviews' },
    { name: 'All Credit Card Reviews', slug: 'all' },
  ];

  const seo = getDefaultSEO(
    location.pathname,
    pageTitle,
    socialTitle,
    ogDescription,
    includeCollectionPageType,
    extraBreadcrumbList,
  );
  return <Seo title={pageTitle} wpSeo={seo} pageNumber={pageNumber} />;
};
