import React, { FC } from 'react';
import { IAllCreditCardReviews } from './AllCreditCardReviews.def';
import PaginationV2 from '../../../PaginationV2/PaginationV2';
import PageHeader from '../../../PageHeader/PageHeader';
import HorizontalBrowser from '../../../HorizontalBrowser/HorizontalBrowser';

const AllCreditCardReviews: FC<IAllCreditCardReviews> = ({
  pagination,
  creditCardReviews,
  pageHeader,
}) => {
  return (
    <div className="all-credit-card-reviews">
      {pageHeader && <PageHeader {...pageHeader} />}
      <div className="container">
        <HorizontalBrowser {...creditCardReviews} componentName="ReviewTile" />
        <PaginationV2 {...pagination} />
      </div>
    </div>
  );
};

export default AllCreditCardReviews;
